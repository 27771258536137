// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-document-js": () => import("./../../../src/pages/document.js" /* webpackChunkName: "component---src-pages-document-js" */),
  "component---src-pages-function-js": () => import("./../../../src/pages/function.js" /* webpackChunkName: "component---src-pages-function-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-q-and-a-js": () => import("./../../../src/pages/qAndA.js" /* webpackChunkName: "component---src-pages-q-and-a-js" */),
  "component---src-pages-working-js": () => import("./../../../src/pages/working.js" /* webpackChunkName: "component---src-pages-working-js" */)
}

